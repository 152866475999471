
import { mdiClose, mdiCrown, mdiCached } from "@mdi/js"
import adminService from "@/services/admin.service"

export default {
    name: "SearchAdminButton",
    props: {
        /** Positioning props - top */
        top: {
            type: Boolean,
            default: false,
        },
        /** Positioning props - bottom */
        bottom: {
            type: Boolean,
            default: true,
        },
        /** Positioning props - right */
        right: {
            type: Boolean,
            default: true,
        },
        /** Positioning props - left */
        left: {
            type: Boolean,
            default: false,
        },
        /** Direction of opening */
        direction: {
            type: String,
            default: "top",
            validator: (value) => {
                return ["top", "right", "bottom", "left"].includes(value)
            },
        },
        /** Open on hover */
        hover: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        mdiClose,
        mdiCrown,
        mdiCached,
    },
    data() {
        return {
            isClearingSearchCache: false,
            isExpanded: false,
        }
    },
    computed: {
        isAdminBtnLoading() {
            return this.isClearingSearchCache
        },
    },
    methods: {
        async clearSearchCache() {
            this.isClearingSearchCache = true
            await adminService.clearSearchCache()
            this.isClearingSearchCache = false
        },
    },
}
